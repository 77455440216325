<template>
    <div>
        <el-row style="margin-left: 80px;margin-top: 20px;">
            <el-col :span="11">
                <h1>实时数据</h1>
                <div class="item">
                    <el-row class="item-row">
                        <el-col :span="2"><img class="icon" src="../assets/icons/heart-content.png"></el-col>
                        <div style="margin-left: 30px;">心率</div>
                        <div style="margin-left: 30px;">72</div>
                    </el-row>
                </div>
                <div class="item">
                    <el-row class="item-row">
                        <el-col :span="2"><img class="icon" src="../assets/icons/heart.png"></el-col>
                        <div style="margin-left: 30px;">血压</div>
                        <div style="margin-left: 30px;">72/140</div>
                    </el-row>
                </div>
                <div class="item">
                    <el-row class="item-row">
                        <el-col :span="2"><img class="icon" src="../assets/icons/heart-content.png"></el-col>
                        <div style="margin-left: 30px;">血氧饱和度</div>
                        <div style="margin-left: 30px;">98%</div>
                    </el-row>
                </div>
                <div class="item">
                    <el-row class="item-row">
                        <el-col :span="2"><img class="icon" src="../assets/icons/heart.png"></el-col>
                        <div style="margin-left: 30px;">运动情况</div>
                        <div style="margin-left: 30px;">运动中</div>
                    </el-row>
                </div>
                <div class="item">
                    <el-row class="item-row">
                        <el-col :span="4"><img class="icon" src="../assets/icons/position.png"></el-col>
                        <el-col :span="24">
                            <div>实时位置</div>
                        </el-col>
                    </el-row>
                    <el-row class="item-row">
                        <el-col :span="24" style="margin-top: -70px;">
                            <LocationMap id="location"></LocationMap>
                        </el-col>
                    </el-row>
                </div>
            </el-col>
            <el-col :span="2" style="justify-content: center;align-items: center;display: flex;height: 100vh;">
                <el-divider direction="vertical"></el-divider>
            </el-col>
            <el-col :span="11">
                <h1>历史数据</h1>
                <el-row class="item-row">
                    <el-col :span="24">
                        <div>（2小时内数据，更早数据请前往数据中心查看）</div>
                    </el-col>
                </el-row>
                <el-row  class="item-row">
                    <el-col :span="12">
                        <h1>今日异常次数</h1>
                    </el-col>
                    <el-col :span="12">
                        <h1 style="color: red" v-if="errorInfo">{{errorInfo.length}}次</h1>
                        <h1 style="color: red" v-else>0</h1>
                    </el-col>
                </el-row>
                <el-row>
                    <el-table
                        :data="errorInfo"
                        stripe
                        style="width: 100%"
                        max-height="400">
                        <el-table-column
                            prop="date"
                            label="时间">
                        </el-table-column>
                        <el-table-column
                            prop="detail"
                            label="异常情况">
                        </el-table-column>
                    </el-table>
                </el-row>
                <div>
                    <el-row class="item-row">
                        <el-col :span="4"><img class="icon" src="../assets/icons/heart-content.png"></el-col>
                        <el-col :span="4">
                            <div>心率波动图像</div>
                        </el-col>
                    </el-row>
                    <el-row class="item-row">
                        <el-col :span="24" style="margin-top: -70px;">
                            <OneLineChart v-if="heart_rate_x" :x-name="heart_rate_x" :line-data="heart_rate_y"
                                          id="heart-line"></OneLineChart>
                        </el-col>
                    </el-row>
                </div>
                <div>
                    <el-row class="item-row">
                        <el-col :span="4"><img class="icon" src="../assets/icons/heart.png"></el-col>
                        <el-col :span="4">
                            <div>血压波动图像</div>
                        </el-col>
                    </el-row>
                    <el-row class="item-row">
                        <el-col :span="24" style="margin-top: -70px;">
                            <OneLineChart v-if="blood_pressure_x" :x-name="blood_pressure_x"
                                          :line-data="blood_pressure_y" y-label="cmHg" id="blood-pressure"></OneLineChart>
                        </el-col>
                    </el-row>
                </div>
                <div>
                    <el-row class="item-row">
                        <el-col :span="4"><img class="icon" src="../assets/icons/heart-content.png"></el-col>
                        <el-col :span="4">
                            <div>血氧波动图像</div>
                        </el-col>
                    </el-row>
                    <el-row class="item-row">
                        <el-col :span="24" style="margin-top: -70px;">
                            <OneLineChart v-if="blood_oxygen_saturation_x" :x-name="blood_oxygen_saturation_x"
                                          :line-data="blood_oxygen_saturation_y" id="blood-oxygen"></OneLineChart>
                        </el-col>
                    </el-row>
                </div>
                <div>
                    <el-row class="item-row">
                        <el-col :span="4"><img class="icon" src="../assets/icons/position.png"></el-col>
                        <el-col :span="4">
                            <div>运动轨迹</div>
                        </el-col>
                    </el-row>
                    <el-row class="item-row">
                        <el-col :span="24">
                            <MapWithPath v-if="live_location_p" id="path-of-particle"
                                         :path-coordinates="live_location_p"></MapWithPath>
                        </el-col>
                    </el-row>
                </div>
            </el-col>
        </el-row>
    </div>
</template>

<script>
import OneLineChart from "@/components/OneLineChart.vue";
import Location from "@/components/Location.vue";
import LocationMap from "@/components/AMap.vue";
import MapWithPath from "@/components/MapWithPath.vue";
import de from "element-ui/src/locale/lang/de";
import th from "element-ui/src/locale/lang/th";
import {convertCoordinates, ExceptionType,getDateFromNumber} from "@/utils/common";

export default {
    name: "UserMain",
    components: {OneLineChart, Location, LocationMap, MapWithPath},
    data() {
        return {
            heart_rate_x: undefined,
            heart_rate_y: undefined,
            blood_pressure_y: undefined,
            blood_pressure_x: undefined,
            blood_oxygen_saturation_x: undefined,
            blood_oxygen_saturation_y: undefined,
            live_location_p: undefined,
            live_location_time: undefined,
            errorInfo: undefined,
        }
    },
    computed: {},
    mounted() {
        this.initData();
    },
    methods: {
        dealDate(type, data) {
            let json = data
            let arr = Object.keys(json).map(key => json[key]);
            let sortedArray = arr.flat().sort((a, b) => a.time - b.time);
            switch (type) {
                case "heart_rate":
                    this.heart_rate_y = sortedArray.reduce((acc, cur) => {
                        acc.push(cur.statics);
                        return acc;
                    }, []);
                    this.heart_rate_x = sortedArray.reduce((acc, cur) => {
                        acc.push(getDateFromNumber(cur.time));
                        return acc;
                    }, []);
                    break;
                case "blood_pressure":
                    this.blood_pressure_y = sortedArray.reduce((acc, cur) => {
                        acc.push(cur.statics);
                        return acc;
                    }, []);
                    this.blood_pressure_x = sortedArray.reduce((acc, cur) => {
                        acc.push(getDateFromNumber(cur.time));
                        return acc;
                    }, []);
                    break;
                case "blood_oxygen_saturation":
                    this.blood_oxygen_saturation_y = sortedArray.reduce((acc, cur) => {
                        acc.push(cur.statics);
                        return acc;
                    }, []);
                    this.blood_oxygen_saturation_x = sortedArray.reduce((acc, cur) => {
                        acc.push(getDateFromNumber(cur.time));
                        return acc;
                    }, []);
                    break;
                case "live_location":
                    this.live_location_p = sortedArray.reduce((acc, cur) => {
                        console.log(cur.statics)
                        let coordinates = cur.statics.replace("N", "");
                        coordinates = coordinates.replace("W", "");
                        let obj = JSON.parse(coordinates)
                        acc.push(convertCoordinates(obj["lon"], obj["lat"]));
                        return acc;
                    }, []);
                    this.live_location_time = sortedArray.reduce((acc, cur) => {
                        acc.push(getDateFromNumber(cur.time));
                        return acc;
                    }, []);
                    break;
                default:
                    break;
            }
        },
        initData() {
            let type = ["heart_rate", "blood_pressure", "blood_oxygen_saturation", "live_location"];
            let url = undefined;
            for (let oneType of type) {
                url = `${this.$api.getUserStatics}?scope=2&staticsName=${oneType}`
                this.$http.get(url).then(res => {
                    console.log(res)
                    if (res.data.code === 0) {
                        switch (oneType) {
                            case "heart_rate":
                                this.dealDate("heart_rate", res.data.data.staticses)
                                break;
                            case "blood_pressure":
                                this.dealDate("blood_pressure", res.data.data.staticses)
                                break;
                            case "blood_oxygen_saturation":
                                this.dealDate("blood_oxygen_saturation", res.data.data.staticses)
                                break;
                            case "live_location":
                                this.dealDate("live_location", res.data.data.staticses)
                                break;
                            default:
                                break;
                        }
                    }
                })
            }
            url = `${this.$api.getExceptionLog}?scope=2`
            this.$http.get(url).then(res => {
                console.log(res)
                let data = res.data.data;
                let json = data
                let arr = Object.keys(json).map(key => json[key]);
                let sortedArray = arr.flat().sort((a, b) => a.time - b.time);
                this.errorInfo = sortedArray.reduce((acc, cur) => {
                    let obj={};
                    obj["date"] = this.getDateFromNumber2(cur.time);
                    obj["detail"] = ExceptionType[cur.type.trim()];
                    acc.push(obj);
                    return acc;
                }, []);

            })
        },
        /*getDateFromNumber(milliseconds) {
            let date = new Date(milliseconds)
            let temp = date.getMonth() + 1
            let day = date.getDate()
            let temp2 = date.getHours()
            let temp3 = date.getMinutes()
            return `${temp}-${day} ${temp2}:${temp3}`;
        },*/
        getDateFromNumber2(milliseconds) {
            let date = new Date(milliseconds)
            let temp2 = date.getHours()
            let temp3 = date.getMinutes()
            let temp4 = date.getSeconds()
            return `${temp2}点${temp3}分${temp4}秒`;
        }
    }
}
</script>

<style scoped>
.el-row {
    margin-bottom: 20px;

    &:last-child {
        margin-bottom: 0;
    }
}

.el-col {
    border-radius: 4px;
}

.bg-purple-dark {
    background: #99a9bf;
}

.bg-purple {
    background: #d3dce6;
}

.bg-purple-light {
    background: #e5e9f2;
}

.grid-content {
    border-radius: 4px;
    min-height: 36px;
}

.row-bg {
    padding: 10px 0;
    background-color: #f9fafc;
}

/deep/ .el-divider--vertical {
    height: 100vh;
}

.icon {
    height: 50px;
    width: 50px;
}

.item-row {
    display: flex;
    align-items: center;
}

#location {
    font-family: "Avenir", Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
    margin-top: 60px;
}
</style>